import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import ViewKanbanOutlinedIcon from "@mui/icons-material/ViewKanbanOutlined";
import ViewQuiltOutlinedIcon from "@mui/icons-material/ViewQuiltOutlined";
import { useSearchItemFilesManager } from "@/src/hook";
import { CMSLogic, SearchLogic } from "@/src/model";
import { StrapiCMSType } from "@/src/type";
import { SearchFileTypes, SearchType } from "@/src/type/blaceV2";
import { BaseButton } from "@src/component/base";
import styles from "./VenueButtonBar.module.scss";

/* prettier-ignore */
interface VenueButtonBarProps {
  venue: SearchType.SearchItem;
  venueMarketingAsset?: StrapiCMSType.StrapiData<
    StrapiCMSType.VenueMarketingAssetType & StrapiCMSType.StrapiAttributes<StrapiCMSType.VenueMarketingAssetType>
  >;
  openMapButton: () => void;
}

function VenueButtonBar({
  venue,
  venueMarketingAsset,
  openMapButton,
}: VenueButtonBarProps) {
  const searchItemFilesManager = useSearchItemFilesManager();

  const venueLocation = venue.locations?.length ? venue.locations[0] : undefined;
  const hasLocation = !!venueLocation?.latitude && !!venueLocation?.longitude;
  const hasTearSheet = typeof getTearSheetURL() === "string";
  const hasFloorPlan = typeof getFloorPlanURL() === "string";

  if (!hasLocation && !hasTearSheet && !hasFloorPlan) {
    return false;
  }

  // todo: migrate to useMemo (find the solution for `venue` and `venueMarketingAsset` changes tracking
  //  (without infinity re-renders)
  function getTearSheetURL() {
    // check V2 data structure
    if (SearchLogic.supportV2Files(venue)) {
      return SearchLogic.buildFileUrl(
        searchItemFilesManager.getFile(venue.files, SearchFileTypes.TearSheet),
      );
    }

    // fallback to V1 data structure
    /* prettier-ignore */
    const url = venueMarketingAsset?.attributes?.venueDetail?.tearSheet?.data?.attributes?.url;
    if (typeof url === "string") {
      return CMSLogic.replaceStorageUrlWithCdnUrl(url);
    }
  }

  function openTearSheat() {
    window.open(getTearSheetURL(), "_blank");
  }

  // todo: migrate to useMemo (find the solution for `venue` and `venueMarketingAsset` changes tracking
  //  (without infinity re-renders)
  function getFloorPlanURL() {
    // check V2 data structure
    if (SearchLogic.supportV2Files(venue)) {
      return SearchLogic.buildFileUrl(
        searchItemFilesManager.getFile(venue.files, SearchFileTypes.FloorPlan),
      );
    }

    // fallback to V1 data structure
    /* prettier-ignore */
    const url = venueMarketingAsset?.attributes?.venueDetail?.floorPlan?.data?.attributes?.url;
    if (typeof url === "string") {
      return CMSLogic.replaceStorageUrlWithCdnUrl(url);
    }
  }

  function openFloorPlan() {
    window.open(getFloorPlanURL(), "_blank");
  }

  return (
    <div className={styles.detailSpecButtonBar}>
      {hasLocation && (
        <BaseButton
          type="submit"
          variant="outlined"
          size="large"
          onClick={openMapButton}
        >
          <MapOutlinedIcon />
          Show Map
        </BaseButton>
      )}
      {hasTearSheet && (
        <BaseButton
          type="submit"
          variant="outlined"
          size="large"
          onClick={openTearSheat}
        >
          <ViewKanbanOutlinedIcon />
          Tear Sheet
        </BaseButton>
      )}
      {hasFloorPlan && (
        <BaseButton
          type="submit"
          variant="outlined"
          size="large"
          onClick={openFloorPlan}
        >
          <ViewQuiltOutlinedIcon />
          Floor Plan
        </BaseButton>
      )}
      {/*
      <BaseButton
        type="submit"
        variant="outlined"
        size="large"
      >
        <ViewInArOutlinedIcon />
        3D Tour
      </BaseButton>
    */}
    </div>
  );
}

export default VenueButtonBar;
