import type { ReactElement } from "react";
import AnnouncementTwoToneIcon from "@mui/icons-material/AnnouncementTwoTone";
import CakeTwoToneIcon from "@mui/icons-material/CakeTwoTone";
import CameraAltTwoToneIcon from "@mui/icons-material/CameraAltTwoTone";
import CampaignTwoToneIcon from "@mui/icons-material/CampaignTwoTone";
import CelebrationTwoToneIcon from "@mui/icons-material/CelebrationTwoTone";
import CollectionsTwoToneIcon from "@mui/icons-material/CollectionsTwoTone";
import ColorLensTwoToneIcon from "@mui/icons-material/ColorLensTwoTone";
import ConnectWithoutContactTwoToneIcon from "@mui/icons-material/ConnectWithoutContactTwoTone";
import DiamondTwoToneIcon from "@mui/icons-material/DiamondTwoTone";
import FavoriteTwoToneIcon from "@mui/icons-material/FavoriteTwoTone";
import GroupsTwoToneIcon from "@mui/icons-material/GroupsTwoTone";
import InterpreterModeTwoToneIcon from "@mui/icons-material/InterpreterModeTwoTone";
import LiquorTwoToneIcon from "@mui/icons-material/LiquorTwoTone";
import MicNoneTwoToneIcon from "@mui/icons-material/MicNoneTwoTone";
import MusicNoteTwoToneIcon from "@mui/icons-material/MusicNoteTwoTone";
import RamenDiningTwoToneIcon from "@mui/icons-material/RamenDiningTwoTone";
import RestaurantTwoToneIcon from "@mui/icons-material/RestaurantTwoTone";
import RocketLaunchTwoToneIcon from "@mui/icons-material/RocketLaunchTwoTone";
import SchoolTwoToneIcon from "@mui/icons-material/SchoolTwoTone";
import ScienceTwoToneIcon from "@mui/icons-material/ScienceTwoTone";
import TableRestaurantTwoToneIcon from "@mui/icons-material/TableRestaurantTwoTone";
import TheatersTwoToneIcon from "@mui/icons-material/TheatersTwoTone";
import WebStoriesTwoToneIcon from "@mui/icons-material/WebStoriesTwoTone";
import WorkspacePremiumTwoToneIcon from "@mui/icons-material/WorkspacePremiumTwoTone";
import { DressSVG } from "@/src/asset";
import {
  DetailSectionInfo,
  DetailSectionInfoBodyIcon,
} from "@/src/component/partial";
import { FILTERS } from "@/src/const";
import { VenueLogic } from "@/src/model";
import { StrapiCMSType } from "@/src/type";
import { SearchType } from "@/src/type/blaceV2";
import { StringHelper } from "@/src/util";
import styles from "./VenueBestUsedFor.module.scss";

interface VenueBestUsedForProps {
  venueAttributes?: SearchType.SearchAttribute[];
}

const BEST_USED_FOR: Record<StrapiCMSType.Data.VenueBestUsedFor, string> = {
  ART_EXHIBITION: "art-exhibition",
  BRAND_ACTIVATION: "brand-activation",
  CEREMONY: "ceremony",
  CONCERT: "concert",
  CONFERENCE: "conference",
  CORPORATE_DINNER: "corporate-dinner",
  CORPORATE_PARTY: "corporate-party",
  EXPERIENTIAL: "experiential",
  FASHION: "fashion",
  FILM_PHOTO: "filmphoto",
  GALA: "gala",
  GALLERY: "gallery",
  HOLIDAY_PARTY: "holiday-party",
  LECTURES_AND_DISCUSSIONS: "lectures-and-discussions",
  MEETING: "meeting",
  MITZVAHS: "mitzvahs",
  NETWORKING_SPACE: "networking-space",
  PUBLIC_TALK: "public-talk",
  PRESS_EVENT: "press-event",
  PRODUCT_LAUNCH: "product-launch",
  PRIVATE_CHEF: "private-chef",
  SCREENING: "screening",
  SHOWROOM: "showroom",
  SOCIAL_EVENT: "social-event",
  WEDDING: "wedding",
};

const BEST_USED_FOR_ICONS: Record<
  StrapiCMSType.Data.VenueBestUsedFor,
  ReactElement
> = {
  ART_EXHIBITION: <ColorLensTwoToneIcon color="inherit" />,
  BRAND_ACTIVATION: <AnnouncementTwoToneIcon color="inherit" />,
  CEREMONY: <WorkspacePremiumTwoToneIcon color="inherit" />,
  CONCERT: <MusicNoteTwoToneIcon color="inherit" />,
  CONFERENCE: <MicNoneTwoToneIcon color="inherit" />,
  CORPORATE_DINNER: <RestaurantTwoToneIcon color="inherit" />,
  CORPORATE_PARTY: <LiquorTwoToneIcon color="inherit" />,
  EXPERIENTIAL: <ScienceTwoToneIcon color="inherit" />,
  FASHION: <DressSVG size={22} fill="currentFill" stroke="currentColor" />,
  FILM_PHOTO: <CameraAltTwoToneIcon color="inherit" />,
  GALA: <DiamondTwoToneIcon color="inherit" />,
  GALLERY: <WebStoriesTwoToneIcon color="inherit" />,
  HOLIDAY_PARTY: <CelebrationTwoToneIcon color="inherit" />,
  LECTURES_AND_DISCUSSIONS: <SchoolTwoToneIcon color="inherit" />,
  MEETING: <TableRestaurantTwoToneIcon color="inherit" />,
  MITZVAHS: <CakeTwoToneIcon color="inherit" />,
  NETWORKING_SPACE: <ConnectWithoutContactTwoToneIcon color="inherit" />,
  PUBLIC_TALK: <InterpreterModeTwoToneIcon color="inherit" />,
  PRESS_EVENT: <CampaignTwoToneIcon color="inherit" />,
  PRODUCT_LAUNCH: <RocketLaunchTwoToneIcon color="inherit" />,
  PRIVATE_CHEF: <RamenDiningTwoToneIcon color="inherit" />,
  SCREENING: <TheatersTwoToneIcon color="inherit" />,
  SHOWROOM: <CollectionsTwoToneIcon color="inherit" />,
  SOCIAL_EVENT: <GroupsTwoToneIcon color="inherit" />,
  WEDDING: <FavoriteTwoToneIcon color="inherit" />,
};

function VenueBestUsedFor({ venueAttributes }: VenueBestUsedForProps) {
  const bestUsedFor = VenueLogic.getAttributesByType("bestUsedFor", venueAttributes);
  if (!bestUsedFor) {
    return <></>;
  }

  return (
    <DetailSectionInfo
      title="Best Used For"
      Component={
        <div className={styles.venueLabelContainer}>
          {Object.keys(BEST_USED_FOR).map((key: any) => {
            /* prettier-ignore */
            const BEST_USED_FOR_KEY = key as StrapiCMSType.Data.VenueBestUsedFor;
            const strikeThrough: boolean = !StringHelper.hasData(
              bestUsedFor[BEST_USED_FOR[BEST_USED_FOR_KEY]]?.attributeId
            );
            const attributeOption = VenueLogic.getAttributeOptionItem(
              BEST_USED_FOR[BEST_USED_FOR_KEY],
              FILTERS.bestUsedFor?.options,
            );
            if (!attributeOption?.label || strikeThrough) {
              // was decided to hide the non-selected Best Used For items
              // the logic keep as is to be consistent with `VenueAmenities`
              return false;
            }

            return (
              <DetailSectionInfoBodyIcon
                key={BEST_USED_FOR_KEY}
                Icon={BEST_USED_FOR_ICONS[BEST_USED_FOR_KEY]}
                tag={strikeThrough ? "body1" : "h4"}
                strikeThrough={strikeThrough}
              >
                {attributeOption.label}
              </DetailSectionInfoBodyIcon>
            );
          })}
        </div>
      }
    />
  );
}

export default VenueBestUsedFor;
