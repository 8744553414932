import { BaseModal } from "@/src/component/base";
import { InquiryForm } from "@/src/component/partial";
import { VENDOR_MAX_GUEST_COUNT, VENUE_MAX_GUEST_COUNT } from "@/src/const";
import { useInquiryFormParams } from "@/src/hook";
import { VendorLogic, VenueLogic } from "@/src/model";
import { BlaceV2Type } from "@/src/type";
import { SearchType } from "@/src/type/blaceV2";
import styles from "./SendInquiryDialog.module.scss";

interface SendInquiryDialogProps {
  inquiryFormData: BlaceV2Type.EventType.InquiryFormDataProps;
  dialogOpen: boolean;
  selectedVendor?: SearchType.SearchItem;
  selectedVenue?: SearchType.SearchItem;
  selectedCatalogId?: string;
  selectedSearchId?: string;
  sendInquiryDialogClose: () => void;
  setInquiryLimitReached: () => void;
}

function SendInquiryDialog({
  dialogOpen,
  inquiryFormData,
  selectedVendor,
  selectedVenue,
  selectedCatalogId,
  selectedSearchId,
  sendInquiryDialogClose,
  setInquiryLimitReached,
}: SendInquiryDialogProps) {
  const inquiryFormParams = useInquiryFormParams({
    selectedVendor,
    selectedVenue,
  });

  return (
    <BaseModal
      dialogProps={{
        open: dialogOpen,
        onClose: () => sendInquiryDialogClose(),
      }}
      isMobileDraggable={false}
      useSlide={false}
      onExit={() => sendInquiryDialogClose()}
    >
      <div className={styles.formWrapper}>
        {selectedVendor && (
          <InquiryForm
            isExclusive={false}
            primaryContact={VendorLogic.getContactName(selectedVendor)}
            pricing={VendorLogic.formatVendorPricing(selectedVendor)}
            hideStartingAt={true}
            altStartingAt="Price Estimate"
            slug={selectedVendor.slug}
            altCatalogId={inquiryFormParams?.altCatalogId}
            catalogId={selectedCatalogId}
            searchId={selectedSearchId}
            inquiryType={BlaceV2Type.InquiryTypes.Vendor}
            maxGuestCount={VENDOR_MAX_GUEST_COUNT}
            sendInquiryDialogClose={sendInquiryDialogClose}
            inquiryFormData={inquiryFormData}
            setInquiryLimitReached={setInquiryLimitReached}
            listing={selectedVendor}
          />
        )}
        {selectedVenue && (
          <InquiryForm
            isExclusive={selectedVenue.facts?.isExclusive}
            primaryContact={VenueLogic.getVenueContact(selectedVenue)}
            pricing={`${VenueLogic.formatVenuePricing(selectedVenue)}`}
            slug={selectedVenue.slug}
            altCatalogId={inquiryFormParams?.altCatalogId}
            catalogId={selectedCatalogId}
            searchId={selectedSearchId}
            inquiryType={BlaceV2Type.InquiryTypes.Venue}
            maxGuestCount={selectedVenue.capacity?.max ?? VENUE_MAX_GUEST_COUNT}
            sendInquiryDialogClose={sendInquiryDialogClose}
            inquiryFormData={inquiryFormData}
            setInquiryLimitReached={setInquiryLimitReached}
            listing={selectedVenue}
          />
        )}
      </div>
    </BaseModal>
  );
}

export default SendInquiryDialog;
